/* App.css - Updated with a mocha-inspired color scheme */

/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

/* Add this import for a retro cursive font */
/* @import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap'); */

:root {
  --primary-color: #1a1a1a;
  --secondary-color: #333333;
  --accent-color: #666666;
  --background-color: #ffffff;
  --text-color: #1a1a1a;
  --light-text-color: #666666;
  --card-width: 250px;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
}

header {
  background-color: white;
  color: black;
  padding: 25px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

.header-title {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

.header-title h1 {
  margin: 0;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
  font-size: 2.4em;
  color: black;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.nav-left, .nav-right {
  display: flex;
  gap: 15px;
  min-width: 150px;
  z-index: 1;
}

.nav-left {
  justify-content: flex-start;
  padding-left: 10px;
}

.nav-right {
  justify-content: flex-end;
  gap: 5px;
  padding-right: 0px;
}

nav {
  display: flex;
  align-items: center;
  margin-top: 0;
}

nav a {
  color: black;
  text-decoration: none;
  margin: 0 15px;
  font-weight: 400;
  transition: color 0.3s ease;
  text-transform: uppercase;
}

nav a:hover {
  color: var(--accent-color);
}

main {
  padding: 20px 20px; /* Reduced top padding from 40px to 20px */
}

.home h2 {
  font-size: 2em;
  margin-bottom: 30px;
  color: var(--primary-color);
}

.product-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}

.product-card {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  width: 300px;
  margin: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.product-image-container {
  position: relative;
  width: 100%;
  padding-top: 100%; /* 1:1 Aspect Ratio */
  overflow: hidden;
}

.product-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
  will-change: transform;
  backface-visibility: hidden;
}

.product-image:nth-child(2) {
  transform: translateX(100%);
}

.product-card:hover .product-image:first-child {
  transform: translateX(-100%);
}

.product-card:hover .product-image:nth-child(2) {
  transform: translateX(0);
}

.product-image-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  color: #888;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.favorite-button {
  position: absolute;
  top: 8px;
  right: 8px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.product-details {
  display: flex;
  justify-content: space-between;
  padding: 8px 12px;
  align-items: center;
  padding-bottom: 0; /* Removed padding below the price */
  margin-bottom: 0;
}

.product-price {
  font-weight: bold;
  font-size: 16px;
}

.product-retailer {
  color: #888;
  font-size: 14px;
  margin-bottom: 4px; /* Added a slight margin-bottom */
}

.product-card h3 {
  margin-top: 0;
  margin-bottom: 10px;
  font-weight: 600;
  color: var(--primary-color);
  font-size: 1.2em;
  line-height: 1.3;
  height: 2.6em; /* Limit to two lines */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.product-card p {
  font-size: 1.1em;
  color: var(--secondary-color);
  margin: 5px 0;
}

.product-card .favorite-button {
  align-self: flex-end;
  margin-top: auto;
  margin-bottom: 10px;
}

.product-card a {
  display: block;
  text-align: center;
  text-decoration: none;
  color: white;
  background-color: var(--primary-color);
  padding: 10px 20px;
  border-radius: 25px;
  font-weight: 400;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

.product-card a:hover {
  background-color: var(--secondary-color);
}

.product-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.main-content {
  display: flex;
  width: 100%;
}

.sidebar {
  position: fixed;
  top: 0;
  left: -350px;
  width: 350px;
  height: 100%;
  background-color: white;
  overflow-x: hidden;
  transition: all 0.3s ease-in-out;
  z-index: 1000;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  opacity: 0;
  visibility: hidden;
}

.sidebar h3 {
  margin-top: 0;
  color: var(--primary-color);
  font-weight: 400;
  font-family: 'Inter', sans-serif;
  font-size: 1.1em;
  text-align: left;
}

.sidebar ul {
  list-style: none;
  padding-left: 0;
  text-align: left;
}

.sidebar li {
  cursor: pointer;
  padding: 10px;
  transition: background-color 0.3s ease;
  border-radius: 0;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  color: var(--text-color);
  text-align: left;
  text-transform: uppercase;
}

.sidebar li.all-trends {
  font-weight: 600;
}

.sidebar li:hover,
.sidebar li.active {
  background-color: var(--secondary-color);
  color: white;
  border-radius: 0;
}

.content-area {
  flex: 1;
  padding: 20px;
}

.filters {
  display: flex;
  justify-content: center;
  gap: 12px; /* Increased from 8px */
  padding: 0 20px;
  max-width: 100%;
  position: sticky;
  top: 20px;
  z-index: 100;
}

.filters select {
  padding: 10px 20px; /* Increased from 8px 16px */
  border: none;
  border-radius: 0;
  background-color: rgba(255, 255, 255, 0.9);
  color: var(--text-color);
  font-family: 'Inter', sans-serif;
  font-size: 15px; /* Increased from 14px */
  transition: all 0.3s ease;
  min-width: 140px; /* Increased from 120px */
  cursor: pointer;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 10px center; /* Adjusted from 8px */
  background-size: 18px; /* Increased from 16px */
  padding-right: 36px; /* Increased from 32px */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8px);
}

.filters select:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.filters select:focus {
  outline: none;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

/* Add padding to content to prevent it from going under fixed filters */
.content-area {
  padding-top: 160px;
}

@media (max-width: 768px) {
  .filters {
    top: 75px;
    padding: 0 10px;
    gap: 5px;
  }

  .filters select {
    padding: 6px 24px 6px 12px;
    font-size: 12px;
    min-width: 90px;
    background-size: 12px;
    border-radius: 0;
  }

  .content-area {
    padding-top: 140px;
  }
}

footer {
  background-color: var(--primary-color);
  color: white;
  padding: 20px 0;
  text-align: center;
}

footer p {
  margin: 0;
  font-size: 0.9em;
}

@media (max-width: 768px) {
  .main-content {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    order: 2;
    margin-top: 20px;
  }

  .content-area {
    order: 1;
  }

  .filters {
    flex-wrap: wrap;
  }

  main {
    padding: 0px 0px; /* Reduced from 20px 20px */
  }

  .content-area {
    padding: 5px; /* Reduced from 20px */
  }

  .product-list-container {
    padding: 0 0px; /* Reduced padding */
  }

  .filters {
    margin-top: -1vh; /* Reduced from -2vh */
    margin-bottom: 8px; /* Reduced from 15px */
    gap: 2px; /* Reduced from 8px */
  }

  .product-grid {
    gap: 4px; /* Reduced from larger gap */
    margin: 0 -2px; /* Reduced negative margin */
  }

  .product-card {
    margin: 1px; /* Reduced from 10px */
  }
}

/* For very small screens */
@media (max-width: 360px) {
  main {
    padding: 5px 2px; /* Even smaller padding */
  }

  .product-grid {
    gap: 6px; /* Further reduced gap */
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* ... (responsive styles) */

/* App.css - Add styles for product images */

/* ... existing styles */

/* ... remaining styles */

/* Product Details Page Styles */
.product-details-page {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.product-main-content {
  display: flex;
  gap: 2rem;
  width: 100%;
}

.product-details-page .product-gallery {
  flex: 1;
  max-width: 50%;
  position: relative;
  overflow: hidden;
}

.product-details-page .main-image {
  position: relative;
  width: 100%;
  padding-top: 100%; /* 1:1 aspect ratio */
  overflow: hidden;
  background-color: #f8f8f8;
  border-radius: 4px;
  transition: all 0.2s ease; /* Updated to transition all properties */
  cursor: ns-resize; /* Add this to indicate vertical scroll functionality */
}

/* Add hover effect with black border */
.product-details-page .main-image:hover {
  border: 2px solid black;
}

.product-details-page .main-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: opacity 0.3s ease-in-out;
}

.product-details-page .thumbnail-images {
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem;
  overflow-x: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  justify-content: center; /* Center the thumbnails */
  padding: 0 10px; /* Add some padding on the sides */
}

.product-details-page .thumbnail-images::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.product-details-page .thumbnail-images img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  cursor: pointer;
  opacity: 0.6;
  transition: opacity 0.3s ease;
  border-radius: 4px;
}

.product-details-page .thumbnail-images img.active {
  opacity: 1;
  border: 2px solid var(--primary-color);
}

.product-details-page .product-info {
  flex: 1;
}

.product-details-page .size-selector .sizes {
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.product-details-page .size-button {
  padding: 0.5rem 1rem;
  border: 1px solid var(--primary-color);
  background-color: white;
  color: var(--primary-color);
  cursor: pointer;
}

.product-details-page .size-button.selected {
  background-color: var(--primary-color);
  color: white;
}

.product-details-page .size-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.product-details-page .color-swatch {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-left: 0.5rem;
  border: 1px solid #ccc;
}

.product-details-page .product-actions {
  margin-top: 2rem;
}

.product-details-page .add-to-cart-button,
.product-details-page .favorite-button-pdp {
  padding: 0.75rem 1.5rem;
  margin-right: 1rem;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 0;
}

.product-details-page .add-to-cart-button {
  background-color: var(--primary-color);
  color: white;
}

.product-details-page .add-to-cart-button.added {
  background-color: #4CAF50;
}

.product-details-page .favorite-button-pdp {
  background-color: white;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

/* ... existing styles */

/* ... remaining styles */

/* Responsive adjustments */
@media (min-width: 768px) {
  .product-main-content {
    flex-direction: row;
  }

  .product-main-content .product-gallery {
    flex: 0 0 50%;
  }

  .product-main-content .product-info {
    flex: 0 0 50%;
  }
}

/* Cart Page Styles */
.cart {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  font-family: 'Poppins', sans-serif;
  text-align: left;
}

.cart h2 {
  font-size: 32px;
  margin-bottom: 20px;
  font-weight: 600;
  color: var(--primary-color);
}

.cart-items {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.cart-item {
  display: flex;
  gap: 20px;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 20px;
}

.cart-item img {
  width: 150px;
  height: 150px;
  object-fit: cover;
}

.cart-item-details {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.cart-item-details h3 {
  font-size: 24px;
  margin-bottom: 10px;
}

.cart-item-details p {
  font-size: 16px;
  margin-bottom: 5px;
}

.cart-total {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  font-size: 24px;
  font-weight: bold;
}

.checkout-button {
  background: var(--primary-color);
  color: white;
  padding: 15px 40px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  letter-spacing: 1px;
  margin-top: 30px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: 'Inter', sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  display: block;
  margin-left: auto;
}

.checkout-button:hover {
  background: var(--secondary-color);
  transform: translateY(-2px);
}

.cart p {
  font-size: 18px;
}

/* Adjust the add-to-cart-button when product is added to cart */
.add-to-cart-button.added {
  background: linear-gradient(45deg, var(--secondary-color), var(--accent-color));
  cursor: not-allowed;
  opacity: 0.7;
}

.add-to-cart-button.added:hover {
  transform: none;
}

/* Style for disabled button */
.add-to-cart-button:disabled {
  cursor: not-allowed;
}

/* Style for the remove-item-button */
.remove-item-button {
  background-color: transparent;
  color: var(--primary-color);
  padding: 10px 20px;
  font-size: 16px;
  border: 2px solid var(--primary-color);
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-radius: 50px;
  font-family: 'Poppins', sans-serif;
  margin-top: 10px;
}

.remove-item-button:hover {
  background-color: var(--accent-color);
  color: white;
}

/* Adjust cart-item-details to position the remove button */
.cart-item-details {
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* ... remaining styles ... */

/* Size Selection Styles */
.size-selector {
  margin-top: 20px;
}

.size-selector .sizes {
  display: flex;
  flex-wrap: wrap; /* Enable wrapping */
  gap: 8px; /* Reduce gap slightly for better spacing when wrapped */
  margin-top: 0.5rem;
}

.size-button {
  padding: 8px 16px; /* Slightly reduced padding */
  border: 2px solid var(--primary-color);
  background-color: var(--background-color);
  color: var(--primary-color);
  font-size: 14px; /* Slightly smaller font */
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 0;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  min-width: 45px; /* Add minimum width to maintain consistency */
  text-align: center;
}

.size-button:hover:not(:disabled) {
  background-color: var(--primary-color);
  color: white;
}

.size-button.selected {
  background-color: var(--primary-color);
  color: white;
}

.size-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  text-decoration: line-through;
  border-color: var(--light-text-color);
  color: var(--light-text-color);
}

/* ... remaining styles ... */

/* Back Button Styles */
.product-details-page .back-button {
  background: none;
  border: none;
  color: var(--primary-color);
  font-size: 1rem;
  cursor: pointer;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  border-radius: 0;
}

.product-details-page .back-button:hover {
  text-decoration: underline;
}

/* Sidebar Animation */
.sidebar {
  position: fixed;
  top: 0;
  left: -350px;
  width: 350px;
  height: 100%;
  background-color: white;
  overflow-x: hidden;
  transition: all 0.3s ease-in-out;
  z-index: 1000;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  opacity: 0;
  visibility: hidden;
}

/* Show sidebar when open */
.sidebar.open {
  left: 0;
  opacity: 1;
  visibility: visible;
}

/* Only show hover effects on home page with narrower trigger area */
.main-content:has(.home) ~ .sidebar {
  display: block;
  visibility: visible;
  opacity: 0;
  clip-path: inset(0 340px 0 0);
}

/* When sidebar is open, remove the clip-path */
.sidebar.open {
  clip-path: none;
}

/* Mobile styles */
@media (max-width: 768px) {
  .sidebar {
    display: none !important; /* Force hide on mobile */
  }
}

/* Ensure content doesn't shift */
.main-content {
  transition: none;
  width: 100%;
  max-width: 100%;
}

.content-area {
  width: 100%;
  padding: 20px;
}

/* Remove the .main-content.shifted style */
.main-content.shifted {
  margin-left: 0;
}

/* Mobile adjustments for filters */
@media (max-width: 600px) {
  .filters {
    gap: 5px;
    margin-bottom: 10px;
  }

  .filters select {
    padding: 4px 6px;
    font-size: 12px;
    border-radius: 12px;
    min-width: 60px;
    max-width: 80px;
    flex: 1 1 calc(33.33% - 10px); /* Adjust flex to fit three selects per row */
  }
}

/* Product Details Price Styles */
.product-details-page .price-container {
  display: flex;
  align-items: baseline;
  gap: 12px;
  margin: 16px 0;
  justify-content: center; /* Add this to center align */
}

.product-details-page .current-price {
  font-size: 24px;
  font-weight: 600;
  color: var(--primary-color);
}

.product-details-page .original-price {
  font-size: 18px;
  color: #999;
  text-decoration: line-through;
  text-decoration-thickness: 1px;
  text-decoration-color: rgba(0, 0, 0, 0.2);
  opacity: 0.8;
}

.product-details-page .discount-percentage {
  font-size: 16px;
  color: #4CAF50;
  font-weight: 500;
}

/* Center the product title as well for consistency */
.product-details-page .product-info h1 {
  text-align: center;
}

/* Update the filters styles for mobile */
@media (max-width: 768px) {
  .filters {
    flex-wrap: nowrap;
    width: 100%;
    padding: 0 10px;
    gap: 5px;
    justify-content: space-between;
  }

  .filters select {
    flex: 1;
    min-width: 0; /* Remove min-width constraint */
    padding: 8px 20px 8px 12px; /* Adjusted padding */
    font-size: 13px; /* Increased from 11px */
    background-position: right 6px center; /* Adjusted from 4px */
    background-size: 12px; /* Increased from 10px */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

/* Remove any padding-top adjustments from content-area if they exist */
.content-area {
  padding-top: initial;
}

/* Update the mobile styles to match */
@media (max-width: 768px) {
  .sidebar {
    width: 100%;
    order: 2;
    margin-top: 20px;
  }
}

/* Update the fixed sidebar styles if they exist */
.sidebar.open {
  left: 0;
  width: 350px; /* Match the new width */
}

/* Add this media query to hide scrub bar on mobile */
@media (max-width: 768px) {
  .scrub-bar,
  .right-hover-area {
    display: none;
  }
  
  /* Remove any potential spacing that was reserved for the scrub bar */
  .product-list-container {
    padding-right: 0;
  }
}

/* Create a container for the title */
.header-title {
  flex: 1;
  text-align: center;
}

/* Style the left and right nav sections */
.nav-left, .nav-right {
  display: flex;
  gap: 15px;
  min-width: 150px; /* Ensures equal space on both sides of title */
}

.nav-left {
  justify-content: flex-start;
}

.nav-right {
  justify-content: flex-end;
}

/* Add these new styles */
.mobile-icon {
  display: none;
}

@media (max-width: 768px) {
  .mobile-icon {
    display: inline-block;
    font-size: 20px;
  }

  .desktop-text {
    display: none;
  }

  nav a {
    margin: 0 0px;
  }

  .nav-left, .nav-right {
    min-width: auto;
  }
}

/* Filter FAB and Modal Styles */
.filter-fab {
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--primary-color);
  color: white;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  display: none; /* Hidden by default */
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1000;
  transition: transform 0.3s ease;
}

.filter-fab:hover {
  transform: scale(1.1);
}

.filter-modal {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  padding: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  transform: translateY(100%);
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 999;
  max-height: 80vh;
  overflow-y: auto;
  visibility: hidden;
}

.filter-modal.open {
  transform: translateY(0);
  visibility: visible;
}

.filter-modal .filters {
  position: static;
  flex-direction: column;
  gap: 15px;
  padding: 0;
  display: flex !important; /* Force display the filters in modal */
}

.filter-modal .filters select {
  width: 100%;
  max-width: none;
  padding: 12px;
  border: 1px solid #eee;
  display: block !important; /* Ensure selects are visible */
  font-size: 14px;
  background-color: white;
}

.filter-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.filter-modal-header h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
}

.close-modal {
  background: none;
  border: none;
  font-size: 24px;
  color: var(--primary-color);
  cursor: pointer;
}

/* Update mobile media query */
@media (max-width: 768px) {
  .filters:not(.filter-modal .filters) {
    display: none; /* Hide only the original filters, not the ones in modal */
  }

  .filter-fab {
    display: flex;
  }
}

/* Add overlay background */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease;
  visibility: hidden;
  z-index: 998;
}

.modal-overlay.open {
  opacity: 1;
  visibility: visible;
}

/* Add these new styles */
.sidebar-hint {
  position: fixed;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  z-index: 999;
  animation: pulseAndFade 3s ease-in-out;
  pointer-events: none;
}

.sidebar-hint svg {
  color: var(--primary-color);
  animation: slideLeftRight 2s ease-in-out infinite;
}

@keyframes pulseAndFade {
  0% {
    opacity: 0;
    transform: translateY(-50%) scale(0.8);
  }
  20% {
    opacity: 1;
    transform: translateY(-50%) scale(1);
  }
  80% {
    opacity: 1;
    transform: translateY(-50%) scale(1);
  }
  100% {
    opacity: 0;
    transform: translateY(-50%) scale(0.8);
  }
}

@keyframes slideLeftRight {
  0%, 100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-4px);
  }
}

/* Hide the hint when sidebar is open */
.sidebar.open ~ .main-content .sidebar-hint {
  display: none;
}

/* Hide on mobile */
@media (max-width: 768px) {
  .sidebar-hint {
    display: none;
  }
}

.main-content.initial-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 50px); /* Adjust based on your header height */
}

.main-content.initial-loading .content-area {
  width: 100%;
  margin-top: -40px; /* Add this line to raise the spinner */
}

/* Styles for similar products section */
.similar-products-section {
  margin-top: -2vh;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  padding: 0 20px;
}

.similar-products-section h2 {
  font-size: 1.5rem;
  /* margin-bottom: 20px; */
  text-align: center;
}

.similar-products-list {
  display: flex;
  overflow-x: auto;
  gap: 20px;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth; /* Make default scrolling smooth */
  padding: 20px 0; /* Add vertical padding to accommodate box-shadow */
  /* Add these properties to prevent vertical scrolling propagation */
  overscroll-behavior-y: contain;
  overscroll-behavior: contain;
}

/* Add this new style to prevent body scroll when hovering over similar products */
.similar-products-section:hover {
  overflow: hidden;
}

/* Add styles for smooth scrolling on hover */
.similar-products-list:hover {
  scroll-behavior: auto; /* Remove smooth scrolling on hover for more precise control */
}

/* Ensure the product cards don't shrink */
.similar-products-list .product-card {
  flex: 0 0 200px;
  min-width: 200px; /* Ensure consistent width */
}

/* Hide scrollbar while keeping functionality */
.similar-products-list::-webkit-scrollbar {
  display: none;
}

.similar-products-list {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* Remove all the following styles as they're no longer needed:
- scroll-snap-type
- cursor styles
- navigation buttons
- complex positioning
- hover effects
*/

/* Search Bar Styles */
.search-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 10px;
}

.search-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  background: white;
  border: 2px solid black;
  border-radius: 0;
  padding: 8px 16px;
  transition: all 0.2s ease;
}

.search-container.focused .search-input-wrapper {
  border-color: black;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
}

.search-icon {
  color: black;
  margin-right: 8px;
}

.search-input-field {
  flex: 1;
  border: none;
  outline: none;
  padding: 8px;
  font-size: 16px;
  background: transparent;
  font-family: 'Inter', sans-serif;
  color: black;
}

.search-input-field::placeholder {
  color: #666666;
}

.clear-button {
  background: none;
  border: none;
  padding: 4px;
  color: black;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 8px;
}

.clear-button:hover {
  color: #666666;
}

.submit-button {
  background: black;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 0;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s ease;
  text-transform: uppercase;
  font-family: 'Inter', sans-serif;
  letter-spacing: 1px;
  font-size: 14px;
}

.submit-button:hover {
  background: #333333;
}

.image-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  max-width: 90%;
  max-height: 90vh;
}

.modal-image {
  max-width: 100%;
  max-height: 90vh;
  object-fit: contain;
}

.close-modal {
  position: absolute;
  top: -40px;
  right: -40px;
  background: none;
  border: none;
  color: white;
  font-size: 30px;
  cursor: pointer;
}

.main-image {
  cursor: pointer;
}

