/* Import the Lobster font */
/* @import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap'); */

.product-card {
    position: relative;
    overflow: hidden;
    padding: 0;
    margin: 0;
    width: 100%;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border: none;
    border-radius: 0;
    background-color: white;
  }
  
  .product-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  }
  
  .product-card .favorite-button {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 2;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  .product-card .product-image-container {
    width: 100%;
    padding-top: 120%; /* Maintain aspect ratio */
    position: relative;
    overflow: hidden;
    border-radius: 0;
  }
  
  .product-card .product-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
    border-radius: 0;
  }
  
  .product-card .first-image {
    z-index: 2;
    transform: translateX(0);
  }
  
  .product-card .second-image {
    z-index: 1;
    transform: translateX(100%);
  }
  
  .product-card:hover .first-image {
    transform: translateX(-100%);
  }
  
  .product-card:hover .second-image {
    transform: translateX(0);
  }
  
  .product-card .product-info-container {
    padding: 8px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: padding 0.3s ease;
  }
  
  .product-card .product-info {
    font-size: calc(0.9em + 0.1vw);
    color: var(--text-color);
    transition: font-size 0.3s ease;
  }
  
  .product-card .product-price,
  .current-price {
    font-family: 'Inter', sans-serif;
    color: var(--primary-color);
    font-weight: 600;
    font-size: calc(1.1em + 0.1vw);
    transition: font-size 0.3s ease;
  }
  
  /* Responsive Typography */
  @media (max-width: 480px) {
    .product-card .product-info {
      font-size: 0.8em;
    }
  
    .product-card .product-price {
      font-size: 0.9em;
    }
  }
  
  .product-card .product-image.preloaded {
    opacity: 1;
  }
  
  .product-image.loading {
    background-color: #f0f0f0;
  }
  
  .product-image.loaded {
    animation: fadeIn 0.5s ease-in-out;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  /* Additional styles for retailer and brand text */
  .product-card .retailer,
  .product-card .brand {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
  
  /* Style for image placeholder and error placeholder */
  .product-card .product-image-placeholder,
  .image-error-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f0f0f0;
    color: #888;
    font-size: 14px;
    width: 100%;
    height: 100%;
  }

/* ProductCard Styles */

/* Container for the product card */
.product-card {
  position: relative;
  overflow: hidden;
  /* other styles */
}

/* Image container to hold the slider */
.product-image-container {
  overflow: hidden;
  width: 100%;
  height: auto; /* Adjust as needed */
}

/* Slider that holds all images side by side */
.image-slider {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

/* Each image inside the slider */
.slider-image {
  min-width: 100%;
  height: auto; /* Ensure images maintain aspect ratio */
  flex-shrink: 0;
}

/* If you have specific height or aspect ratio requirements */
.slider-image img {
  width: 100%;
  height: auto;
}

/* Optimize image hover animations */
.product-image {
  transition: transform 0.3s ease;
}

.product-card:hover .product-image:first-child {
  transform: translateX(-100%);
}

.product-card:hover .product-image:nth-child(2) {
  transform: translateX(0);
}

/* Ensure the product card takes full width of its container */
.product-card {
  width: 100%;
  /* ... existing styles ... */
}

/* ... existing styles ... */

@media (max-width: 768px) {
  .product-card .product-image-container {
    padding-top: 130%; /* Slightly taller aspect ratio for mobile */
  }

  .product-card .product-info {
    font-size: 0.9em; /* Slightly larger than before */
  }

  .product-card .product-price {
    font-size: 1em; /* Slightly larger than before */
  }

  .product-card .product-info-container {
    padding: 10px 14px; /* Increased padding */
  }
}

.current-price {
    color: black;
    font-weight: normal;
}

.product-card .retailer {
    /* Remove the Lobster font, letting it inherit the default font */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.product-info-container {
  padding: 8px 12px;
}

.info-row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 2px;
}

.product-info.retailer,
.product-info.brand {
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-size: 0.85em;
  width: 100%; /* Allow full width */
}

.price-container {
  display: flex;
  align-items: baseline;
  gap: 8px;
  white-space: nowrap;
  padding: 4px 0;
  margin-left: 0; /* Remove auto margin */
}

.original-price {
  color: var(--light-text-color);
  text-decoration: line-through;
  font-weight: 400;
}

.current-price {
  color: var(--primary-color);
}

/* Responsive adjustments */
@media (max-width: 480px) {
  .product-info.retailer,
  .product-info.brand,
  .current-price,
  .original-price {
    font-size: 0.75em;
  }
}

/* Remove any potential color inheritance from other styles */
.price-container {
    color: black;
}

/* Responsive adjustments */
@media (min-width: 769px) {
  .info-row {
    flex-direction: row;
    align-items: center;
  }

  .price-container {
    margin-left: auto;
  }
}

.product-card.favorited {
  position: relative;
  background-color: black;
  border: 2px solid black;
}

.product-card.favorited .product-info.retailer,
.product-card.favorited .product-info.brand {
  color: white;
}

.product-card.favorited .current-price {
  color: white;
}

.product-card.favorited .original-price {
  color: rgba(255, 255, 255, 0.7);
}

/* Update hover effect - no animation */
.product-card:hover {
  transform: none;
  box-shadow: none;
}

.product-card.favorited:hover {
  transform: none;
  box-shadow: none;
  background-color: black;
}

/* Add styles for favorited card text */
.product-card.favorited .product-info.retailer,
.product-card.favorited .product-info.brand {
  color: white;
}

.product-card.favorited .current-price {
  color: white;
}

.product-card.favorited .original-price {
  color: rgba(255, 255, 255, 0.7); /* Slightly transparent white for crossed-out price */
}

/* Remove any potential color inheritance from other styles */
.price-container {
  color: black;
}

/* Remove transitions */
.product-card {
  transition: none;
}

/* Optimize image hover animations */
.product-card .product-image {
  transition: transform 0.3s ease;
  will-change: transform;
}

.product-card .first-image {
  transform: translateX(0);
}

.product-card .second-image {
  transform: translateX(100%);
}

.product-card:hover .first-image {
  transform: translateX(-100%);
}

.product-card:hover .second-image {
  transform: translateX(0);
}

/* Show favorite button on hover */
.product-card:hover .favorite-button,
.product-card.favorited .favorite-button {
  opacity: 1;
}

/* Retailer and Brand visibility */
.product-info.retailer {
  display: block;
}

.product-info.brand {
  display: none;
}

.product-card:hover .product-info.retailer {
  display: none;
}

.product-card:hover .product-info.brand {
  display: block;
}
