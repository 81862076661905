/* src/components/ProductList.css */

/* Apply box-sizing globally */
*, *::before, *::after {
  box-sizing: border-box;
}

/* Product List Container */
.product-list-container {
  position: relative;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 4px;
}

/* Product Grid Styles */
.product-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  justify-content: center;
  transition: all 0.3s ease;
  margin: 0 -2px;
}

/* Product Card Wrapper */
.product-card-wrapper {
  width: var(--card-width, 270px);
  transition: width 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
}

/* Scrub Bar Styles */
.scrub-bar {
  position: fixed;
  top: 0;
  right: 0;
  width: 40px;
  height: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 1000;
}

/* Hover Area to Trigger the Scrub Bar */
.right-hover-area {
  position: fixed;
  top: 0;
  right: 0;
  width: 30px;
  height: 100%;
  z-index: 999;
}

/* Make scrub bar visible on hover */
.right-hover-area:hover ~ .scrub-bar,
.scrub-bar:hover {
  opacity: 1;
}

/* Slider Styling */
.card-width-slider {
  width: 500px;
  height: 30px;
  transform: rotate(-90deg);
  transform-origin: center;
  -webkit-appearance: none;
  background: transparent;
  margin: 0;
}

/* Slider Track */
.card-width-slider::-webkit-slider-runnable-track {
  width: 100%;
  height: 6px;
  background: #ccc;
  border-radius: 3px;
}

.card-width-slider::-moz-range-track {
  width: 100%;
  height: 6px;
  background: #ccc;
  border-radius: 3px;
}

.card-width-slider::-ms-track {
  width: 100%;
  height: 6px;
  background: #ccc;
  border-radius: 3px;
  border: none;
  color: transparent;
}

/* Slider Thumb */
.card-width-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  background: var(--primary-color);
  border-radius: 50%;
  margin-top: -5px;
  cursor: pointer;
}

.card-width-slider::-moz-range-thumb {
  width: 16px;
  height: 16px;
  background: var(--primary-color);
  border: none;
  border-radius: 50%;
  cursor: pointer;
}

.card-width-slider::-ms-thumb {
  width: 16px;
  height: 16px;
  background: var(--primary-color);
  border: none;
  border-radius: 50%;
  cursor: pointer;
}

/* Hover effects for slider thumb */
.card-width-slider:hover::-webkit-slider-thumb {
  background: var(--secondary-color);
}

.card-width-slider:hover::-moz-range-thumb {
  background: var(--secondary-color);
}

.card-width-slider:hover::-ms-thumb {
  background: var(--secondary-color);
}

/* Responsive adjustments for mobile devices */
@media (max-width: 768px) {
  .product-list-container {
    padding: 0 4px;
    max-width: 100vw;
  }

  .product-grid {
    justify-content: center;
    gap: 6px;
  }

  .product-card-wrapper {
    width: calc(50% - 4px);
    min-width: 150px;
    max-width: none;
  }
}

/* For very small screens */
@media (max-width: 360px) {
  .product-grid {
    gap: 4px;
  }

  .product-card-wrapper {
    width: calc(50% - 3px);
    min-width: 140px;
  }
}

/* New styles for hiding rightmost column */
.hide-rightmost .product-card-wrapper:nth-child(4n) {
  width: 0 !important;
  opacity: 0;
  visibility: hidden;
  margin: 0;
  padding: 0;
}

/* Back to Top button styles */
.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 30px;
  background-color: var(--primary-color);
  color: white;
  padding: 15px 25px;
  border: none;
  border-radius: 0;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  z-index: 1000;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.back-to-top.visible {
  opacity: 1;
  visibility: visible;
}

.back-to-top:hover {
  background-color: var(--secondary-color);
}

@media (max-width: 768px) {
  .back-to-top {
    bottom: 30px;
    right: 25px;
    padding: 12px 20px;
    font-size: 14px;
  }
}
