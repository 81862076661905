.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

/* Different styles for initial load vs infinite scroll loading */
.loading-container.initial-load {
  min-height: calc(100vh - 60px);
  overflow: visible;
}

/* New class for infinite scroll loading state */
.loading-container:not(.initial-load) {
  height: 200px;
  overflow: hidden;
  margin: 20px 0;
}

.cyber-scene {
  position: relative;
  width: 600px;
  height: 400px;
  background: #ffffff;
  overflow: visible;
  display: flex;
  justify-content: center;
  align-items: center;
  perspective: 1500px;
  border-radius: 4px;
}

/* Adjust scene size based on loading state */
.initial-load .cyber-scene {
  width: 800px;
  height: 600px;
}

:not(.initial-load) .cyber-scene {
  transform: scale(0.5);
  height: 200px;
}

.grid-plane {
  position: absolute;
  width: 800%;
  height: 800%;
  background: 
    linear-gradient(90deg, rgba(0,0,0,0.1) 1px, transparent 1px) 0 0 / 50px 50px,
    linear-gradient(rgba(0,0,0,0.1) 1px, transparent 1px) 0 0 / 50px 50px;
  transform: rotateX(60deg);
  animation: gridMove 20s linear infinite;
  transform-origin: center center;
}

.geometric-container {
  position: relative;
  width: 300px;
  height: 300px;
  transform-style: preserve-3d;
  animation: containerRotate 16s linear infinite;
}

.dodecahedron {
  position: absolute;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  animation: float 4s ease-in-out infinite;
}

.face {
  position: absolute;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  background: rgba(0,0,0,0.05);
  border: 2px solid rgba(0,0,0,0.8);
  backface-visibility: visible;
}

.face-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, rgba(0,0,0,0.1), transparent);
}

/* Dodecahedron face transformations */
.face-1  { transform: rotateX(0deg) translateZ(80px); }
.face-2  { transform: rotateX(72deg) translateZ(80px); }
.face-3  { transform: rotateX(144deg) translateZ(80px); }
.face-4  { transform: rotateX(216deg) translateZ(80px); }
.face-5  { transform: rotateX(288deg) translateZ(80px); }
.face-6  { transform: rotateY(72deg) translateZ(80px); }
.face-7  { transform: rotateY(144deg) translateZ(80px); }
.face-8  { transform: rotateY(216deg) translateZ(80px); }
.face-9  { transform: rotateY(288deg) translateZ(80px); }
.face-10 { transform: rotateY(360deg) translateZ(80px); }
.face-11 { transform: rotateX(180deg) rotateZ(36deg) translateZ(80px); }
.face-12 { transform: rotateX(-180deg) rotateZ(-36deg) translateZ(80px); }

.orbital-ring {
  position: absolute;
  width: 240px;
  height: 240px;
  border: 2px solid rgba(0,0,0,0.3);
  border-radius: 50%;
  transform: rotateX(60deg);
  animation: ringPulse 3s ease-in-out infinite;
}

.orbital-ring-2 {
  position: absolute;
  width: 280px;
  height: 280px;
  border: 1px solid rgba(0,0,0,0.2);
  border-radius: 50%;
  transform: rotateX(60deg) rotateZ(60deg);
  animation: ringPulse 3s ease-in-out infinite reverse;
}

.scan-lines {
  position: absolute;
  width: 100%;
  height: 100%;
  background: repeating-linear-gradient(
    0deg,
    rgba(0,0,0,0.05) 0px,
    rgba(0,0,0,0.05) 1px,
    transparent 1px,
    transparent 4px
  );
  animation: scanEffect 8s linear infinite;
}

@keyframes gridMove {
  0% {
    transform: rotateX(60deg) translateY(0);
  }
  100% {
    transform: rotateX(60deg) translateY(50px);
  }
}

@keyframes containerRotate {
  0% {
    transform: rotateY(0deg) rotateX(20deg);
  }
  100% {
    transform: rotateY(360deg) rotateX(20deg);
  }
}

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

@keyframes ringPulse {
  0%, 100% {
    transform: rotateX(60deg) scale(1);
    opacity: 0.3;
  }
  50% {
    transform: rotateX(60deg) scale(1.1);
    opacity: 0.5;
  }
}

@keyframes scanEffect {
  0% {
    opacity: 0.5;
    transform: translateY(-50%);
  }
  100% {
    opacity: 0.5;
    transform: translateY(50%);
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .cyber-scene {
    width: 300px;
    height: 200px;
  }
  
  .geometric-container {
    transform: scale(0.6);
  }
}

/* Add styles for the searching state */
.loading-container.searching {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.9);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.searching-text {
  margin-top: 20px;
  font-size: 1.2rem;
  color: var(--primary-color);
  font-family: 'Inter', sans-serif;
  text-transform: uppercase;
  letter-spacing: 2px;
}

/* Adjust the cyber scene for searching state */
.searching .cyber-scene {
  transform: scale(0.8);
  margin-top: -60px;
}

/* Add a fade-in animation */
.loading-container.searching {
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}